var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-button',{staticStyle:{"margin-bottom":"16px"},attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.$router.push('/extension/gatewayWholesale/add')}}},[_vm._v("添加商品")]),_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"90px"}},[_c('el-form-item',{attrs:{"label":"商品名称："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入商品名称"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"20px"}},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.getDataList(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":_vm.searchClear}},[_vm._v("清除搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.formData.list,"header-cell-style":{ 'background-color': '#F8F9FA' },"row-key":"id"}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"title","label":"商品信息","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"goods_info"},[_c('el-image',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":row.goods_image,"preview-src-list":[row.goods_image]}}),_c('div',{staticClass:"goods_name"},[_vm._v(_vm._s(row.goods_title))])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"goods_price","label":"价格","align":"center"}}),_c('el-table-column',{attrs:{"label":"第一阶起批量","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ladder_price_info[0].start)+" ~ "+_vm._s(row.ladder_price_info[0].end)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"first_price","label":"批发价格","align":"center"}}),_c('el-table-column',{attrs:{"label":"第二阶起批量","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ladder_price_info[1].start)+" ~ "+_vm._s(row.ladder_price_info[1].end)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"second_price","label":"批发价格","align":"center"}}),_c('el-table-column',{attrs:{"label":"第三阶起批量","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.ladder_price_info[2].start)+"及以上 ")]}}])}),_c('el-table-column',{attrs:{"prop":"third_price","label":"批发价格","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.editData(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteData(row)}}},[_vm._v("删除")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.formData.total,"page":_vm.formData.page,"pageNum":_vm.formData.rows},on:{"updatePageNum":_vm.updateData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }